<template>
  <div>
    <div class="logo mb-3 text-center mt-base">
      <img
        :src="require('@/assets/images/logo/logo-dark.png')"
        alt="logo"
        style="max-width: 150px; max-height: 75px;"
      />
      <h3 class="mt-3 mb-base text-center">
        Create New Organisation
      </h3>
    </div>
    <form @submit.prevent="submit">
      <vs-row class="w-1/2 md:1/3 lg: 1/4 text-center align-center justify-center mx-auto">
        <vs-col>
          <vx-card>
            <vs-row>
              <vs-col xs="12" sm="12" lg="12">
                  <vs-input
                    v-model="form.name"
                    label="Name"
                    class="w-full"
                    :disabled="submit_disabled"
                    placeholder="Enter Organisation Name"
                    required
                    :danger="api_errors.name"
                    :danger-text="api_errors.name ? api_errors.name.join(',') : null"
                  />
                </vs-col>
                <vs-col xs="12" class="mt-3">
                  <vs-button color="primary" @click.prevent="submit()" :disabled="submit_disabled">Save</vs-button>
                </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
      </vs-row>
    </form>
  </div>
</template>


<script>
import { Request } from '../../utils/requests'
export default {
  data() {
    return {
      form: {
        name: null
      },
      submit_disabled: false,
      api_errors: {}
    }
  },
  methods: {
    submit() {
      this.submit_disabled = false
      Request(this, 'POST', this.$store.state.apiendpoints.organisation, null, this.form).then(
        res => {
          this.$store.dispatch('organisation/updateActiveOrganisation', res)
          this.$store.dispatch('organisation/updateOrganisationUser', res.user)
          this.$router.push(this.$route.query.next || "/")
          this.$vs.notify({
            time: 3000,
            title: 'Success',
            text: "Organisation has been created successfully!",
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success'
          })
        },
        err => {
          let msg = "An API Error Occurred, please try again!"
          this.submit_disabled = false
          if (err.error) {
            this.api_errors = err.error
            msg = "Please fix the errors and try again!"
          }

          this.$vs.notify({
            time: 3000,
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      )
    }
  }
}
</script>
